import React from "react"
import LazyLoad from "react-lazyload";
import Images from '../images'
import '../../../node_modules/sal.js/dist/sal.css'
import './style.scss'

const ProjectsBox = props => (
    <div  data-sal="slide-up"
    data-sal-delay="300"
    data-sal-easing="ease"
    data-sal-duration="700"
    >
        <LazyLoad height={200}>
            <div className="work-box">
                <div className="left-img">
                    <a href={props.link} target="_blank">
                        <Images alt={props.name} filename={props.imageurl} />
                    </a>
                </div>
                <div className="right-text">
                    <p className="year">{props.year}</p>
                    <a href={props.link} target="_blank">
                    <h3 className="semibold">{props.name}</h3>
                    </a>
                    <p>{props.description}</p>
                    <a href={props.link} target="_blank">
                        {props.link}
                    </a>
                </div>
            </div>
        </LazyLoad>
    </div>
);

export default ProjectsBox;