import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsBox from "../components/ProjectsBox"

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects" />
    <section className="projects-page">
      <div className="container">
        <div className="row">
          <div className='col-md-12'>

            <ProjectsBox
              imageurl="projects/align.jpg"
              year="2020"
              name="Align With Olie by Jessica Olie"
              description="Yoga & fitness app landing page. Custom design."
              link="https://alignwitholie.com/"
            />

            <ProjectsBox
              imageurl="projects/aldo.jpg"
              year="2020"
              name="Expedition Fit by Aldo Kane"
              description="Landing page for online fitness video course."
              link="https://aldokane.com/"
            />

            <ProjectsBox
              imageurl="projects/starlash.jpg"
              year="2020"
              name="Star Lash by Yris Palmer"
              description="Custom e-commerce website for Yris Palmer."
              link="https://starlashextensions.com/"
            />

            <ProjectsBox
              imageurl="projects/nadya.jpg"
              year="2020"
              name="Nadya Shah"
              description="Shopify e-commerce website for premium women's clothing brand."
              link="https://nadyashah.com/"
            />

            <ProjectsBox
              imageurl="projects/reach.jpg"
              year="2020"
              name="Reach My Kitchen"
              description="Shopify e-commerce website for premium food delivery service based in London."
              link="https://reachmykitchen.com/"
            />

            <ProjectsBox
              imageurl="projects/frstplace.jpg"
              year="2020"
              name="FRSTPLACE by Jordyn Woods"
              description="Landing page and members area."
              link="https://frstplace.com/"
            />

            <ProjectsBox
              imageurl="projects/ajbxng.jpg"
              year="2019"
              name="AJBXNG by Anthony Joshua"
              description="Custom e-commerce website for Anthony Joshua's brand AJBXNG."
              link="https://anthonyjoshua.com/"
            />

            <ProjectsBox
              imageurl="projects/shreddy.jpg"
              year="2019"
              name="Shreddy App"
              description="Fitness app landing page."
              link="https://shreddy.com/"
            />

            <ProjectsBox
              imageurl="projects/bnd.jpg"
              year="2019"
              name="B_ND"
              description="Custom e-commerce website for fitness accessories brand B_ND by Grace Beverley."
              link="https://b-ndstore.com/"
            />

            <ProjectsBox
              imageurl="projects/olie.jpg"
              year="2019"
              name="OLIE"
              description="Custom e-commerce website for Jessica Olie's brand OLIE."
              link="https://thisisolie.com/"
            />

            <ProjectsBox
              imageurl="projects/demi.jpg"
              year="2019"
              name="Demi Bagby Fit App"
              description="Fitness app landing page."
              link="https://demibagbyfit.com/"
            />

            <ProjectsBox
              imageurl="projects/kariella.jpg"
              year="2019"
              name="Kariella"
              description="Shopify e-commerce website for boutique fashion store based in LA."
              link="https://kariella.com/"
            />

            <ProjectsBox
              imageurl="projects/gadzhi.jpg"
              year="2019"
              name="GADZHI"
              description="Custom e-commerce website for fashion brand by Iman Gadzhi."
              link="https://gadzhiclothing.com/"
            />

            <ProjectsBox
              imageurl="projects/break.jpg"
              year="2019"
              name="The Break Platform"
              description="Online video course platform and landing page."
              link="https://thebreakplatform.com/"
            />

            <ProjectsBox
              imageurl="projects/strong.jpg"
              year="2019"
              name="Strong and Sxy App"
              description="Custom fitness app landing page."
              link="https://strongandsxy.com/"
            />

            <ProjectsBox
              imageurl="projects/natacha.jpg"
              year="2019"
              name="Mocean"
              description="Custom e-commerce website for fitness accessories brand Mocean by Natacha Oceane."
              link="https://natachaoceane.com/"
            />

            <ProjectsBox
              imageurl="projects/shona.jpg"
              year="2019"
              name="Shona Vertue"
              description="Fitness and merchandise website for yoga instructor Shona Vertue."
              link="https://shonavertue.com/"
            />

            <ProjectsBox
              imageurl="projects/genflow.jpg"
              year="2019"
              name="Genflow"
              description="Business landing page custom built on React."
              link="https://genflow.com/"
            />

            <ProjectsBox
              imageurl="projects/autobrava.jpg"
              year="2019"
              name="Autobrava Motors"
              description="Business landing page built on Wordpress."
              link="https://autobravamotors.lt/"
            />

            <ProjectsBox
              imageurl="projects/ntvertinimai.jpg"
              year="2019"
              name="NT vertinimai"
              description="Custom real estate valuation website."
              link="https://ntvertinimai.lt/"
            />

            <ProjectsBox
              imageurl="projects/realta.jpg"
              year="2018"
              name="Realta"
              description="Web solution for Real Estate agency."
              link="https://silly-albattani-c12496.netlify.com/"
            />

            <ProjectsBox
              imageurl="projects/bolt.jpg"
              year="2017"
              name="Bolt Markets"
              description="Business website for investment and finance company based in London."
              link="https://boltmarkets.com/"
            />

            <ProjectsBox
              imageurl="projects/moji.jpg"
              year="2017"
              name="Moji.lt"
              description="Custom Wordpress website."
              link="https://moji.lt/"
            />

            <ProjectsBox
              imageurl="projects/customfilterz.jpg"
              year="2017"
              name="Customfilterz"
              description="Snapchat marketing services web solution built on Wordpress."
              link="https://customfilterz.com/"
            />

            <ProjectsBox
              imageurl="projects/nomnom.jpg"
              year="2016"
              name="Nomnom App"
              description="iOS & Android app UI/UX, website design."
              link="https://gust.com/companies/nomnom-app"
            />

          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default ProjectsPage;
